import React, { useState } from 'react'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'

import Box from './Box'
import labelStyles from '../styles/label'
import title3Styles from '../styles/title3'
import bodyStyles from '../styles/body'
import nl2br from '../utils/nl2br'
import More from './More'
import Spacer from './Spacer'
import Title from './Title'

const Wrapper = styled.div``

const Cols = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-bottom: -20px;
`

const Col = styled.div`
  padding-left: 20px;
  flex: 1;
  text-align: center;
  display: flex;
  margin-bottom: 20px;
`

const Year = styled.div`
  ${labelStyles};
  margin-bottom: 0.25rem;
`

const Headline = styled.h2`
  ${title3Styles}
  margin: 0;
  padding: 0;
`

const Link = styled.a`
  display: block;
  text-decoration: none;
  display: flex;
  width: 100%;
  text-align: center;
`

const Items = styled.div``

const Item = styled.div`
  text-align: center;
  padding: 0 0 1rem 0;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 168px;
    height: 1px;
    background: ${props => props.theme.colors.primary};
  }

  h2 {
    ${bodyStyles}
  }
`

const Hidden = styled(AnimateHeight)`
  overflow: hidden;
`

const Publication = ({ publication }) => (
  <>
    <Year>{publication.date}</Year>
    <Headline
      dangerouslySetInnerHTML={{
        __html: nl2br(publication.title.title),
      }}
    />
  </>
)

const Featured = ({ publication }) => (
  <Box>
    <Publication publication={publication} />
  </Box>
)

function filterPublications(publications, onlyShowWithMedia) {
  if (!onlyShowWithMedia) {
    return publications
  }

  return publications.filter(
    publication => publication.link || publication.document
  )
}

const Publications = ({ publications, onlyShowWithMedia = false }) => {
  const [showMore, setShowMore] = useState(false)

  const filteredPublications = filterPublications(
    publications,
    onlyShowWithMedia
  )

  const featured = filteredPublications.slice(0, 3)
  const others = filteredPublications.slice(3)

  const othersByYear = others.reduce((accumulator, publication) => {
    const { date } = publication
    if (!(date in accumulator)) {
      accumulator[date] = []
    }
    accumulator[date].push(publication)
    return accumulator
  }, {})

  return (
    <Wrapper>
      <Cols>
        {featured.map(publication => (
          <Col key={publication.id}>
            {publication.link ? (
              <Link
                href={publication.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Featured publication={publication} />
              </Link>
            ) : (
              <Featured publication={publication} />
            )}
          </Col>
        ))}
      </Cols>
      <Spacer size="small" />
      <Hidden duration={500} height={showMore ? 'auto' : 0}>
        <Items>
          {Object.keys(othersByYear)
            .reverse()
            .map(key => (
              <div key={key}>
                <Title top="Artikel">{key}</Title>
                {othersByYear[key].map(publication => (
                  <div key={publication.id}>
                    {publication.link ? (
                      <Link
                        href={publication.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Item key={publication.id}>
                          <Publication publication={publication} />
                        </Item>
                      </Link>
                    ) : (
                      <Item key={publication.id}>
                        <Publication publication={publication} />
                      </Item>
                    )}
                  </div>
                ))}
                <Spacer size="small" />
              </div>
            ))}
        </Items>
      </Hidden>
      {!showMore && <More onClick={() => setShowMore(true)} />}
    </Wrapper>
  )
}

export default Publications
