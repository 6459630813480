import React from 'react'
import styled, { css } from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FormattedMessage } from 'react-intl'

import Button from './Button'
import labelSmallStyles from '../styles/labelSmall'
import title3Styles from '../styles/title3'

const Wrapper = styled.div``

const Year = styled.div`
  ${labelSmallStyles};
  margin-bottom: 0.25rem;
`

const Title = styled.h2`
  ${title3Styles}
  margin: 0 0 2rem 0;
  padding: 0;
`

const Main = styled.div`
  display: flex;
  margin-left: -20px;
  margin-bottom: 2rem;

  @media (max-width: 540px) {
    flex-direction: column;
  }
`

const sharedColumnStyles = css`
  padding-left: 20px;
`

const Intro = styled.div`
  ${sharedColumnStyles};
  flex-basis: 33.333333%;
  width: 33.333333%;

  @media (max-width: 860px) {
    display: none;
  }
`

const Content = styled.div`
  ${sharedColumnStyles};
  flex: 1;

  ul {
    margin: 0;
  }

  ul p {
    margin: 0;
  }

  @media (max-width: 540px) {
    order: 2;
  }
`

const MobileIntro = styled.div`
  display: none;
  @media (max-width: 860px) {
    display: block;
  }
`

const Media = styled.div`
  ${sharedColumnStyles};

  @media (max-width: 540px) {
    order: 1;
    margin-bottom: 2rem;
  }
`

const Img = styled.img`
  display: block;
  width: 170px;
  height: auto;
`

const Action = styled.div`
  padding: 0 10px; /* simulate column padding */
  width: 33.333333%;
  margin: 0 auto;

  @media (max-width: 860px) {
    width: 100%;
    text-align: center;

    > a {
      display: inline-flex;
    }
  }
`

const Book = ({ book }) => (
  <Wrapper>
    <Year>{book.date}</Year>
    <Title>{book.title}</Title>
    <Main>
      <Intro>{documentToReactComponents(book.intro.json)}</Intro>
      <Content>
        <MobileIntro>{documentToReactComponents(book.intro.json)}</MobileIntro>
        {book.content &&
          book.content.json &&
          documentToReactComponents(book.content.json)}
      </Content>
      <Media>
        <a href={book.link}>
          <Img src={book.preview.fixed.src} alt={book.title} />
        </a>
      </Media>
    </Main>
    <Action>
      <Button as="a" href={book.link} fullWidth target="_blank">
        <FormattedMessage id="book.available-here" />
      </Button>
    </Action>
  </Wrapper>
)

export default Book
