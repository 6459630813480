import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import { useSpring, animated } from 'react-spring'

const Wrapper = styled.div`
  padding: 2rem;
  position: relative;
`

const borderStyles = css`
  position: absolute;
  background: ${props => props.theme.colors.primary};
`

const borderHorizontalStyles = css`
  ${borderStyles};
  height: 1px;
`

const borderVerticalStyles = css`
  ${borderStyles};
  width: 1px;
`

const BorderTop = styled(animated.div)`
  ${borderHorizontalStyles};
  top: 0;
  left: 0;
`

const BorderBottom = styled(animated.div)`
  ${borderHorizontalStyles};
  bottom: 0;
  right: 0;
`

const BorderLeft = styled(animated.div)`
  ${borderVerticalStyles};
  left: 0;
  top: 0;
`

const BorderRight = styled(animated.div)`
  ${borderVerticalStyles};
  right: 0;
  bottom: 0;
`

const Box = ({ children, ...props }) => {
  const [ref, inView] = useInView({ threshold: 0.75 })

  const borderHorizontalProps = useSpring({
    width: inView ? '100%' : '0%',
    from: { width: '0%' },
  })

  const borderVerticalProps = useSpring({
    height: inView ? '100%' : '0%',
    from: { height: '0%' },
  })

  return (
    <Wrapper ref={ref} {...props}>
      {children}
      <BorderTop style={borderHorizontalProps} />
      <BorderBottom style={borderHorizontalProps} />
      <BorderLeft style={borderVerticalProps} />
      <BorderRight style={borderVerticalProps} />
    </Wrapper>
  )
}

export default Box
