import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage, useIntl } from 'react-intl'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Title from '../components/Title'
import Book from '../components/Book'
import Publications from '../components/Publications'
import Spacer from '../components/Spacer'

const PublicationsPage = ({ data }) => {
  const intl = useIntl()

  const books = data.allContentfulBook.edges.map(edge => {
    const { node } = edge
    return Object.assign(node)
  })

  const publications = data.allContentfulPublication.edges.map(edge => {
    const { node } = edge
    const url =
      node.link || (node.document ? node.document.file.url : null) || null
    return Object.assign(node, {
      title: node.title,
      link: url,
    })
  })

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'publications.title' })} />
      <Content intro>
        <Title top={intl.formatMessage({ id: 'publications.title' })}>
          <FormattedMessage id="publications.headline" />
        </Title>
        {books.map(book => (
          <div key={book.id}>
            <Book book={book} />
            <Spacer size="small" />
          </div>
        ))}
        <Spacer />
        <Title top="">
          <FormattedMessage id="articles" />
        </Title>
        <Publications publications={publications} onlyShowWithMedia />
        <Spacer size="small" />
      </Content>
    </Layout>
  )
}

export default PublicationsPage

export const i18n = {
  en: '/publications',
  de: '/publikationen',
}

export const query = graphql`
  query($i18nCurrentLocale: String!) {
    # Books
    allContentfulBook(
      filter: { node_locale: { eq: $i18nCurrentLocale } }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          title
          date(formatString: "YYYY")
          link
          content {
            json
          }
          intro {
            json
          }
          preview {
            fixed(quality: 100, width: 170) {
              src
            }
          }
        }
      }
    }

    # Publications
    allContentfulPublication(
      sort: { order: DESC, fields: date }
      filter: { node_locale: { eq: $i18nCurrentLocale } }
    ) {
      edges {
        node {
          id
          title {
            title
          }
          date(formatString: "YYYY")
          document {
            file {
              url
            }
          }
          link
        }
      }
    }
  }
`
